import React from "react";
import {
  Kanban,
  ListFilter,
  CircleDollarSign,
  ArrowDown,
  ArrowUp,
} from "lucide-react";
import axios from "axios";
import { useEffect, useState } from "react";
import CountUp from "react-countup";
import toast from "react-hot-toast";
import { Loader } from "lucide-react";

const Widgets = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    totalDeposit: 0,
    totalWithdrawal: 0,
    totalBalance: 0,
  });

  useEffect(() => {
    handleGetStats();
  }, []);

  const handleGetStats = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/stats/totalDW`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const { totalDeposit, totalWithdrawal, totalBalance } = response.data;
      setData({ totalDeposit, totalWithdrawal, totalBalance });
    } catch (error: any) {
      if (!error.response) {
        return toast.error("Network error. Please try again.");
      }
      if (typeof error.response.data === "string") {
        return toast.error(error.response.data);
      }
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center  justify-center w-[80vw] h-full min-h-[500px]">
        <Loader className="text-blue-500 animate-spin" />
      </div>
    );
  }

  return (
    <div className="w-full grid grid-cols-3 gap-x-5">
      <div className="order-1 md:col-span-1 lg:col-span-1 col-span-12 2xl:order-1 bg-green-100 dark:bg-green-500/20 card 2xl:col-span-1 group-data-[skin=bordered]:border-green-500/20 relative overflow-hidden">
        <div className="card-body">
          <Kanban className="absolute top-0 size-32 stroke-1 text-green-200/50 dark:text-green-500/20 ltr:-right-10 rtl:-left-10"></Kanban>
          <div className="flex items-center justify-center size-12 bg-green-500 rounded-md text-15 text-green-50">
            <ArrowDown />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp end={data.totalDeposit} className="counter-value" />$
          </h5>
          <p className="text-slate-500 dark:text-slate-200">Total Deposit</p>
        </div>
      </div>
      <div className="order-2 md:col-span-1 lg:col-span-1 col-span-12 2xl:order-1 bg-orange-100 dark:bg-orange-500/20 card 2xl:col-span-1 group-data-[skin=bordered]:border-orange-500/20 relative overflow-hidden">
        <div className="card-body">
          <ListFilter className="absolute top-0 size-32 stroke-1 text-orange-200/50 dark:text-orange-500/20 ltr:-right-10 rtl:-left-10"></ListFilter>
          <div className="flex items-center justify-center size-12 bg-orange-500 rounded-md text-15 text-orange-50">
            <ArrowUp />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp
              end={data.totalWithdrawal}
              decimals={2}
              className="counter-value"
            />
            $
          </h5>
          <p className="text-slate-500 dark:text-slate-200">Total Withdrawal</p>
        </div>
      </div>

      <div className="order-3 md:col-span-1 lg:col-span-1 col-span-12 2xl:order-1 bg-sky-100 dark:bg-sky-500/20 card 2xl:col-span-1 group-data-[skin=bordered]:border-sky-500/20 relative overflow-hidden">
        <div className="card-body">
          <ListFilter className="absolute top-0 size-32 stroke-1 text-sky-200/50 dark:text-sky-500/20 ltr:-right-10 rtl:-left-10"></ListFilter>
          <div className="flex items-center justify-center size-12 rounded-md bg-sky-500 text-15 text-sky-50">
            <CircleDollarSign />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp
              className="counter-value"
              end={data.totalBalance}
              duration={3}
            />
            $
          </h5>
          <p className="text-slate-500 dark:text-slate-200">
            Total Trading Account Balance
          </p>
        </div>
      </div>
      {/* <div className="order-4 md:col-span-6 lg:col-span-3 col-span-12 2xl:order-1 bg-purple-100 dark:bg-purple-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-purple-500/20 relative overflow-hidden">
                <div className="card-body">
                    <Kanban className="absolute top-0 size-32 stroke-1 text-purple-200/50 dark:text-purple-500/20 ltr:-right-10 rtl:-left-10"></Kanban>
                    <div className="flex items-center justify-center size-12 bg-purple-500 rounded-md text-15 text-purple-50">
                        <Users />
                    </div>
                    <h5 className="mt-5 mb-2">
                    <CountUp end={49.77} decimals={2} className="counter-value" />%
                        </h5>
                    <p className="text-slate-500 dark:text-slate-200">Bounce Rate</p>
                </div>
            </div> */}

    </div>
  );
};

export default Widgets;
