import BreadCrumb from "Common/BreadCrumb";
import React, { useEffect, useMemo, useState } from "react";

// icons
import TableContainer from "Common/TableContainer";
import { Loader, Search } from "lucide-react";

// Formik
import axios from "axios";
import { ToastContainer } from "react-toastify";
import toast from "react-hot-toast";
import { useAuthStore } from "store/useAuthStore";

const Referrals = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataList, setDataList] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const { user } = useAuthStore();
  const [createIbRequest, setCreateIbRequest] = useState<boolean>(false);

  useEffect(() => {
    handleGetReferrals();
  }, []);

  const handleInitiateReferral = async () => {
    try {
      setCreateIbRequest(true);
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URI}/ibrequest`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Referral initiated successfully");
      window.location.reload();
    } catch (error) {
      toast.error("Failed to initiate referral");
    } finally {
      setCreateIbRequest(false);
    }
  };

  const handleGetReferrals = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/referral`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setDataList(res.data);
      setData(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // Search Data
  const filterSearchData = (e: any) => {
    const search = e.target.value;
    const keysToSearch = ["_id", "name", "email", "agent"];
    const searchResult = dataList.filter((item: any) => {
      return keysToSearch.some((key) => {
        return (
          item[key] &&
          item[key].toString().toLowerCase().includes(search.toLowerCase())
        );
      });
    });
    setData(searchResult);
  };

  useEffect(() => {
    setDataList(data);
  }, []);

  const columns = useMemo(
    () => [
      {
        header: (
          <div className="flex items-center h-full">
            <input
              id="CheckboxAll"
              className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
              type="checkbox"
            />
          </div>
        ),
        enableSorting: false,
        id: "checkAll",
        cell: (cell: any) => {
          return (
            <div className="flex items-center h-full">
              <input
                id={"Checkbox" + cell.row.original.id}
                className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800"
                type="checkbox"
              />
            </div>
          );
        },
      },
      {
        header: "User ID",
        accessorKey: "_id",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => (
          <>
            <p className="text-slate-500 dark:text-zink-200">
              {cell.getValue()}
            </p>
          </>
        ),
      },
      {
        header: "User Name",
        accessorKey: "name",
        enableColumnFilter: false,
      },
      {
        header: "User Email",
        accessorKey: "email",
        enableColumnFilter: false,
      },
      {
        header: "Total Accounts",
        accessorKey: "accounts",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => (
          <>
            <p>
              {cell.getValue().length > 0
                ? cell.getValue().length
                : "No Account"}
            </p>
          </>
        ),
      },

      {
        header: "Agent",
        accessorKey: "agent",
        enableColumnFilter: false,
      },
    ],
    []
  );

  if (user?.IbRequest?.status === "Pending") {
    return (
      <div className="card">
        <div className="card-body">
          <div className="py-6 text-center">
            <h5 className="mt-2 mb-1">IB Request is Pending</h5>
            <p className="mb-0 text-slate-500 dark:text-zink-200">
              Your IB request is pending. Please wait for the admin to approve
              your request.
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (!user?.hasIb) {
    return (
      <div className="card">
        <div className="card-body">
          <div className="py-6 text-center">
            <h5 className="mt-2 mb-1">Sorry! You are not an IB</h5>
            <button
              onClick={handleInitiateReferral}
              className="bg-custom-500 mt-5 text-white px-4 py-2 rounded-md shadow-md hover:bg-custom-600"
            >
              {createIbRequest ? "Creating IB Request" : "Create IB Request"}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <BreadCrumb title="All refferals" pageTitle="refferals" />
      <div
        onClick={() => {
          navigator.clipboard.writeText(
            `https://portal.ezifx.com/register?refid=${user?.ib}`
          );
          toast.success("Referral link copied to clipboard");
        }}
        className="flex gap-2"
      >
        <h6>Your Referral link: </h6>
        <p
        className="text-custom-500 cursor-pointer"
        >{`https://portal.ezifx.com/register?refid=${user?.ib}`}</p>
      </div>
      <br />
      <ToastContainer closeButton={false} limit={1} />
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-white bg-opacity-90 dark:bg-zink-900 dark:bg-opacity-90">
          <Loader className="size-10 text-custom-500 animate-spin" />
        </div>
      )}
      <div className="card" id="ordersTable">
        <div className="card-body">
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-12">
            <div className="lg:col-span-3">
              <div className="relative">
                <input
                  type="text"
                  className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Search for ..."
                  autoComplete="off"
                  onChange={(e) => filterSearchData(e)}
                />
                <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
              </div>
            </div>
          </div>

          {data && data.length > 0 ? (
            <TableContainer
              isPagination={true}
              columns={columns || []}
              data={data || []}
              customPageSize={10}
              divclassName="mt-5 overflow-x-auto"
              tableclassName="w-full whitespace-nowrap"
              theadclassName="ltr:text-left rtl:text-right bg-slate-100 dark:bg-zink-600"
              thclassName="px-3.5 py-2.5 font-semibold text-slate-500 border-b border-slate-200 dark:border-zink-500 dark:text-zink-200"
              tdclassName="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
            />
          ) : (
            <div className="noresult">
              <div className="py-6 text-center">
                <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                <p className="mb-0 text-slate-500 dark:text-zink-200">
                  We've searched all referrals, but we did not find any
                  referrals for your search.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Referrals;
