import BalanceTransferForm from "components/Forms/Validation/BalanceTransferForm";

const BalanceTransferPage = () => {
  return (
    <div
    className="pt-12"
    >
        <BalanceTransferForm />
    </div>
  )
}

export default BalanceTransferPage;