import React, { useState, useEffect } from "react";

const Analysis = () => {
  const [isClient, setIsClient] = useState(false);
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (isClient) {
      const timeout = setTimeout(() => {
        try {
          const script1 = document.createElement("script");
          script1.src =
            "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
          script1.async = true;
          script1.innerHTML = JSON.stringify({
            "symbols": [
              {
                "proName": "FOREXCOM:SPXUSD",
                "title": "S&P 500 Index"
              },
              {
                "proName": "FOREXCOM:NSXUSD",
                "title": "US 100 Cash CFD"
              },
              {
                "proName": "FX_IDC:EURUSD",
                "title": "EUR to USD"
              },
              {
                "description": "GBP USD",
                "proName": "FX:GBPUSD"
              },
              {
                "description": "USD CAD",
                "proName": "FX:USDCAD"
              },
              {
                "description": "AUD USD",
                "proName": "FX:AUDUSD"
              },
              {
                "description": "USD CAD",
                "proName": "OANDA:USDCAD"
              },
              {
                "description": "GBP JPY",
                "proName": "FX:GBPJPY"
              },
              {
                "description": "USD CHF",
                "proName": "OANDA:USDCHF"
              },
              {
                "description": "GBP AUD",
                "proName": "OANDA:GBPAUD"
              },
              {
                "description": "AUD CHF",
                "proName": "OANDA:AUDCHF"
              }
            ],
            width: "100%",
            height: "100%",
            defaultColumn: "overview",
            screener_type: "crypto_mkt",
            "showSymbolLogo": true,
            displayCurrency: "USD",
            colorTheme: theme,
            locale: "en",
            isTransparent: false,
          });
          document
            .getElementsByClassName("tradingview-widget-container__widget")[0]
            ?.appendChild(script1);
        } catch (error) {
          console.error("Error loading scripts:", error);
        }
      }, 1000);

      return () => {
        clearTimeout(timeout);
        const widgetContainers = document.getElementsByClassName(
          "tradingview-widget-container__widget"
        );
        if (widgetContainers.length > 0) {
          widgetContainers[0].innerHTML = "";
        }
        if (widgetContainers.length > 1) {
          widgetContainers[1].innerHTML = "";
        }
        const ecom = document.getElementById("economicCalendarWidget");
        if (ecom) {
          ecom.innerHTML = "";
        }
      };
    }
  }, [isClient]);

  if (!isClient) {
    return null;
  }

  return (
    <div>
      <div className="w-full ">
        <div className="tradingview-widget-container">
          <div className="tradingview-widget-container__widget"></div>
        </div>
      </div>
    </div>
  );
};

export default Analysis;
